/*
 * @Author: WYY 
 * @Description: 
 * @ModuleName: 图片放大
 * @Date: 2019-01-11 17:27:20 
 * @Last Modified by: WYY
 * @Last Modified time: 2019-01-25 11:42:49
 */
<template>
    <el-dialog :visible="show_" :before-close='Close' custom-class='Tancu' :close-on-click-modal='false'>
        <div class="Ima_box" @mousemove="move" @mousedown="begin" @mouseup="end" @mouseleave="end" @mouseout="end">
            <div class="cover_"></div>
            <img v-if="show_" ref="Image" class="image"  :src="url"  :style="'transfrom:scale(' + scale + ')'"  alt="" @load="imgLoad" >
            <div class="scale">
                <i class="el-icon-zoom-out" @click="scaleAn(0)"></i>
                |
                <i class="el-icon-zoom-in" @click="scaleAn(1)"></i>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        props:['show','url'],
        data(){
            return {
                show_:this.show,
                scale:1,
                pan:false,
                moveData:null,
                style:'',
            }
        },
        created() {
            console.log(this.show_)
        },
        watch: {
            show:function(v){
                this.show_ = v;
                if(v){
                    this.scale = 1;
                }
            }
        },
        methods: {
            Close(){
                this.$emit('update:show',false);
            },
            imgLoad(e){
                let el = e.path[1];
                e.path[0].style.top = '0px';
                e.path[0].style.left = '0px';
                el.style.width = e.path[0].clientWidth + 'px';
                el.style.height = e.path[0].clientHeight + 'px';
            },
            scaleAn(type){
                if(type == 0){
                    if(this.scale>0.5){
                        this.scale -= 0.2;
                        this.$refs['Image'].style.transform = `scale(${this.scale})`;
                    }
                }else if(type == 1){
                    if(this.scale<2){
                        this.scale += 0.2;
                        this.$refs['Image'].style.transform = `scale(${this.scale})`;
                    }
                }
            },
            begin(){
                this.pan = true;
            },
            end(){
                this.pan = false;
            },
            move(item){
                if(this.pan){
                    if(!!this.moveData){
                        let {offsetX,offsetY} = this.moveData;
                        let oldOffsetX = item.offsetX;
                        let oldOffsetY = item.offsetY;
                        let x = offsetX - oldOffsetX;
                        let y = offsetY - oldOffsetY;
                        let {top,left} = this.$refs['Image'].style;
                        top = parseInt(top);
                        left = parseInt(left);
                        if(x>0){
                            left -= 2;
                        }else{
                            left += 2;
                        }
                        if(y>0){
                            top -= 2;
                        }else{
                            top += 2;
                        }
                        this.$refs['Image'].style.top = top + 'px';
                        this.$refs['Image'].style.left = left + 'px';
                        this.moveData = item;
                    }else{
                        this.moveData = item;
                    }
                }
            }
        },
        computed: {
            
        },
    }
</script>

<style scoped>
.Tancu{
    height: 80vh;
}

</style>